import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { redesignTheme } from '@rebeldotcom/components'
import { ErrorBoundary } from '@rebeldotcom/ui'
import * as RebelPanels from '../components/panels'
import Layout from '../components/layout'
import { SEO } from '../components/seo'
import './fragments'

// We use kebab-case when referring to a panel in a JSON page file
// We use PascalCase for naming components
const getPanelComponent = panelType => {
  const panelKey = Object.keys(RebelPanels).find(
    p => p.toLowerCase() === panelType.replace(/-/g, '')
  )

  return RebelPanels[panelKey]
}

const propTypes = {
  data: PropTypes.shape({
    pagesJson: PropTypes.shape({
      layoutProps: PropTypes.shape({
        removeNavigation: PropTypes.bool,
        showNewsletterSignup: PropTypes.bool,
      }),
      panels: PropTypes.arrayOf(PropTypes.shape({})),
      reseller: PropTypes.string,
      seo: PropTypes.shape({
        canonical: PropTypes.string,
        description: PropTypes.string,
        noindex: PropTypes.bool,
        title: PropTypes.string,
      }).isRequired,
      slug: PropTypes.string.isRequired,
      variant: PropTypes.oneOf(['old', 'new']),
    }),
  }).isRequired,
}

const Panels = ({ data }) => {
  const {
    panels,
    seo: pageSEO,
    slug,
    layoutProps,
    variant,
    reseller,
  } = data.pagesJson
  const mainContent =
    panels &&
    panels.map((panel, idx) => {
      const Component = getPanelComponent(panel.type)
      if (!Component) {
        // eslint-disable-next-line no-console
        console.warn(
          `Couldn't find a component for ${panel.type}, please implement me`
        )
        return null
      }

      return (
        <ErrorBoundary key={idx} location={`${slug}-${panel.type}`}>
          <Component key={idx} data={panel} variant={variant} />
        </ErrorBoundary>
      )
    })

  const { title, description, imageUrl, noindex, canonical } = pageSEO
  const showNewsletterSignup = layoutProps?.showNewsletterSignup
  const hasNavigation = layoutProps?.removeNavigation

  return (
    <Layout
      hasNavigation={!hasNavigation}
      location={slug}
      showNewsletterSignup={showNewsletterSignup}
    >
      <SEO
        canonical={canonical}
        description={description}
        imageUrl={imageUrl}
        location={slug}
        noindex={noindex}
        title={title}
      />
      {mainContent}
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!, $reseller: String) {
    pagesJson(slug: { eq: $slug }, reseller: { eq: $reseller }) {
      slug
      layoutProps {
        showNewsletterSignup
        removeNavigation
      }
      seo {
        title
        description
        imageUrl
        noindex
        canonical
      }
      reseller
      variant
      panels {
        type
        ...HeroPanel
        ...LargeProductGridPanel
        ...TrustPilotPanel
        ...ImageTrinityPanel
        ...OurTeamPanel
        ...FAQPanel
        ...AccordionPanel
        ...ProductDetailsPanel
        ...CTAPanel
        ...CTAPairPanel
        ...NewsletterPanel
        ...SpreadPanel
        ...FeatureComparisonPanel
        ...InfoCollector
        ...TwinPanel
        ...SimpleSearchPanel
        ...WordPressThemesPanel
        ...AvailabilityBarPanel
        ...TldSearchPanel
        ...TestimonialPanel
        ...RWDContractorPanel
        ...Careers
        ...AnnouncementPanel
      }
    }
  }
`
Panels.propTypes = propTypes

export default Panels
